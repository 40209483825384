import axios from 'axios'
import Vue from 'vue'
import qs, { stringify } from 'query-string'
import VueCookies from 'vue-cookies'
import Portal from '../components/portal/portal'
import KYC from '../components/form-kyc/form-kyc'
import { auth, envelopes } from 'libvf'

export default {
	name: 'app',
	data() {
		return {
			debugging: false,
			qp: [],
			template: undefined,
			translations: {},
			configuration: {},
			envelope: undefined,
			lang: "nb_NO",
			loading: true,
			exceptions: [],
			attachments: {},
			attachments_checked: {},
			hidePortalContent: true,
			contentOpacity: 1,
			formOpacity: 1,
			step: 0,
			steps: [],
			searchResult: null,
			//searchResultOpacity: 0,
			temp: {},
			role: undefined,
			countries: [],
			customerType: [],
			//searching: false,
			//searchCompleted: false,
			signers: {
				client: null,
				clientPosition: null,
				clientEmail: null,
				clients: [],
				clientsDd: [],
				self: null,
				selfPosition: null,
				selfEmail: null,
				selfs: [],
				selfsDd: []
			},
			userdata: {},
			ud: {
				form: '',
				greeting: null,
				healthContacts: [],
				propertyContacts: [],
				company: {}
			}
		}
	},
	watch: {
		'signers.client': function(newVal) {
			let i = this.signers.clientsDd.indexOf(this.signers.client)
			if(newVal && i !== -1 && this.signers.clients[i].BusinessPosition)
				this.signers.clientPosition = this.signers.clients[i].BusinessPosition.Description
		},
		'signers.self': function(newVal) {
			let i = this.signers.selfsDd.indexOf(this.signers.self)
			if(newVal && i !== -1) {
				this.signers.selfPosition = this.signers.selfs[i].position
				this.signers.selfEmail = this.signers.selfs[i].email
			}
		}
	},
	methods: {
		// Return translation for given key
		__(key) {
			if(this.translations[key])
				return this.translations[key][this.$root.locale]
		},
		// Return config label for given key and language, or value if no label
		__config(key) {
			if(this.configuration[key])
				if(this.configuration[key][this.$root.locale])
					return this.configuration[key][this.$root.locale]
				else
					return this.configuration[key].value
		},
		getURL() {
			var qp = {}

			if (location.search) {
				if (location.search.substring(1, 2) == "&") {
					qp = location.search.substring(0, 1) + location.search.substring(2, location.search.length);
					qp = JSON.parse("{\"" + decodeURI(qp.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
				} else
					qp = JSON.parse("{\"" + decodeURI(location.search.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
			}
			
			this.sheetName = qp.values || "bankid-aml-company";
			this.template = this.sheetName
			console.log(this.sheetName)
		},
		colorLuminance(hex, lum) {
			// Validate hex string
			hex = String(hex).replace(/[^0-9a-f]/gi, '')
			if(hex.length < 6)
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			lum = lum || 0;
			
			// Convert to decimal and change luminosity
			let rgb = '#', c, i
			for(i = 0; i < 3; i++) {
				c = parseInt(hex.substr(i * 2, 2), 16)
				c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
				rgb += ('00' + c).substr(c.length)
			}
			return rgb
		},
		async init(role) {
			this.role = role
			// eslint-disable-next-line no-console
			if (this.debugging) console.log(this.role);

			if(this.__config('background')) {
				document.body.style.backgroundColor = "#f4f4f4"
				document.documentElement.style.background = "#f4f4f4"
			}

			if(this.role === "editor") {
				try {
					this.envelope = await envelopes.getFromUrl()
					let data = await this.envelope.firstTemplate().getUserData()
					this.ud.form = data.form
					this.ud = {...this.ud, ...data}
					this.userdata = this.ud
					this.role = auth.role

					if (this.ud.finalSubmit) {
						if (localStorage.getItem(this.envelope.data.id)) {
							this.signUrl = localStorage.getItem(this.envelope.data.id);
							console.log('signUrl = ' + this.signUrl);
							window.location.href = this.signUrl;
							this.loading = false;
							this.$forceUpdate();
						} else {
							this.alreadyFilled = true;
							this.loading = false;
						}
					} else {
						this.fadeToStep(1)
					}
				}
				catch (e) {
					console.log(e)
					$cookies.remove("user")
					$cookies.remove("token")
					this.loading = false
				}
			} else {
				this.hidePortalContent = false
			}
		},
		async formSelected(value) {
			//Form or URL (can't have space)
			if(value && value.indexOf(" ") !== -1) {
				this.hidePortalContent = true
				this.ud.form = value.split(" ")[1]
				/*
				setTimeout(() => {
					this.initDefaultAttachments()
					this.$forceUpdate()
				}, 1000)
				*/
				
				if(this.ud.form === 'search')
					this.fadeToStep(20)
				else if(this.ud.form === 'select')
					this.fadeToStep(0)
				else
					this.fadeToStep(1)
			} else if(value) {
				try {
					let token = $cookies.get("token")
					let user = $cookies.get("user")
					let link = value// + "?access_token=" + token + "&c=" + user.namespace.split('/').pop()
					window.open(link)
				} catch(e) {
					console.error(e)
					this.exceptions.push(new Error('An error occured during get bearer token'))
				}
			}
		},
		fadeToStep(step) {
			this.formOpacity = 0
			setTimeout(() => {
				this.step = step
				if(step === 0)
					this.hidePortalContent = false
				setTimeout(() => {
					this.contentOpacity = this.formOpacity = 1
				}, 10)
			}, 500)
		},
		nextStep() {
			if (this.step < 5)
				this.fadeToStep(this.step + 1)
			else
				this.submit()
		},
		companySelected(company) {
			if(company) {
				this.ud.company = company
				
				// Init signers
				if(company.roles && company.roles.length) {
					this.signers.clients = company.roles.filter((el) => {
						return el.BusinessPosition && (el.BusinessPosition.BusinessPositionCode === 'DL' || el.BusinessPosition.BusinessPositionCode === 'SF')
					})
					
					this.signers.clients.forEach((el) => {
						if(el.BusinessPosition && el.Person && el.Person.Name && el.Person.Name.LastName && el.Person.Name.FirstName) {
							let name = el.Person.Name.LastName.toLowerCase() + ', ' + el.Person.Name.FirstName.toLowerCase() + ' (' + el.BusinessPosition.Description + ')'
							
							//Capitalize
							this.signers.clientsDd.push(name.replace(/(?:^|\s)\S/g, (a) => { return a.toUpperCase() }))
						}
					})
				}
			}
		},
		initDefaultAttachments() {
			Object.values(this.attachments).forEach((atc, i) => {
				this.attachments_checked[i] = atc.default_for_form.includes(this.ud.form)
			})
			
			let atcs = document.getElementsByClassName('attachments')
			if(atcs.length)
				try {
					for(let i = 0; i < atcs.length; i++)
						for(let j = 0, nodes = atcs[i].childNodes[0].childNodes; j < nodes.length; j++)
							if(this.attachments_checked[j])
								nodes[j].classList.add('default')
							else
								nodes[j].classList.remove('default')
				} catch(e) {}
		},
		setLanguage (value){
			this.lang = value
			this.$root.locale = this.lang
			this.setIso(this.$root.locale)
			this.$forceUpdate()
		}
		/* setSearch (val) {
			this.searching = val
			this.$forceUpdate()
		},
		setSearchData (obj) {
			if(obj) {
				this.searchResult = obj
				setTimeout(() => {
					this.searchCompleted = true
					this.$forceUpdate()
				}, 500)
			}
		} */
	},
	async created() {
		// eslint-disable-next-line no-console
		if (!this.debugging) console.log("Logging disabled");

		let parsed = qs.parse(location.search)
		// First of all, get the name of the google sheet from URL
		//this.sheetName = window.location.hash.split('#/')[1]
		
		/*
		if(!this.sheetName)
			this.getURL()
		if(!this.sheetName)
			return this.exceptions.push(new Error("Sheet was not specified in the url hash")) */


		this.sheetName = 'fossco-kyc'
		this.template = this.sheetName

		Vue.use(VueCookies)
		Vue.use(Portal)
		Vue.use(KYC)
		
		this.$root.locale = 'nb_NO'
		this.setIso("nb_NO")
		//this.$root.octicons = Octicons
		//#/bankid-aml-company#
		
		//Get sheets data
		try {
			this.loadingLists = true
			// Get sheet data
			const [translations, kyc, portal, config, brreg] = await Promise.all([
				axios.get(`https://sheets.web-services.verified.eu/${this.sheetName}/translations`),
				axios.get(`https://sheets.web-services.verified.eu/${this.sheetName}/kyc-translations`),
				axios.get(`https://sheets.web-services.verified.eu/${this.sheetName}/portal-translations`),
				axios.get(`https://sheets.web-services.verified.eu/${this.sheetName}/config`),
				axios.get('https://sheets.web-services.verified.eu/brreg-translations/brreg-translations'),
				
			])
			
			this.translations = { ...translations.data, ...kyc.data, ...portal.data, ...brreg.data }
			this.configuration = config.data
			

			this.ud.translations = { ...translations.data, ...kyc.data, ...portal.data, ...brreg.data } 
			
			this.mergeLocale(this.translations)

			this.mergeLocale({
				"validation.checkbox": {
					en_EN: "Checkbox must be select",
					nb_NO: "Påkrevd"
				}
			})

			//Set page favicon and title
			if(this.__config('favicon'))
				document.getElementById('favicon').href = this.__config('favicon')
			if(this.__config('title'))
				document.title = this.__config('title')
			
			//Set CSS colors
			if(this.__config('primary_color')) {
				document.documentElement.style.setProperty('--primary', this.__config('primary_color'))
				document.documentElement.style.setProperty('--highlight', this.colorLuminance(this.__config('primary_color'), -0.18))
				document.documentElement.style.setProperty('--secondary', this.__config('secondary_color'))
				document.documentElement.style.setProperty('--navbar', this.__config('navbar_color'))
				document.documentElement.style.setProperty('--flag-back', this.__config('flag_back_color'))
				document.documentElement.style.setProperty('--account-name', this.__config('account_name_color'))
				document.documentElement.style.setProperty('--account-company', this.__config('account_company_color'))
			}


			if(this.__config('background')) {
				document.body.style.backgroundColor = "transparent"
				document.documentElement.style.background = this.__config('background')
			}
			
		} catch(e) {
			console.error(e)
			this.exceptions.push(new Error('Unable to get sheet data. Try refreshing your browser.'))
		}
		
		this.loading = false
	}
}