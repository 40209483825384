import axios from 'axios'
import { auth, envelopes, oidc } from 'libvf'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Datepicker from 'vuejs-datepicker'
import {nbNO, en} from 'vuejs-datepicker/dist/locale'
//import Brreg from '../brreg/brreg'
import zipNO from '../../assets/postnummer_no.json'

export default {
	name: "form-kyc",
	props: {
		sheetname: {
			type: String
		},
		role: {
			type: String
		},
		lang: {
			type: String
		},
		userdata: {
			type: Object,
			default: function() { return {} }
		},
		envelope: {
			type: Object,
			default: function() { return {} }
		},
		config: {
			type: Object,
			default: function() { return {} }
		},
		translations: {
			type: Object
		}
	},
	data() {
		return {
			debugging: true,
			en: en,
			no: nbNO,
			zipJson: undefined,
			startDate: new Date('1970'+"-"+'01'+"-"+'01'),
			loading: true,
			searching: false, // AML ?
			searchComplete: false, // AML ?
			//alreadyCompletedSearch: false,
			//brreg_search: true,
			companyNameHolder: undefined, // NY
			brregSearchCompleted: false,
			logout: "../logout.png",
			loader: "../loader64.gif",
			new_doc: "../new-document.png",
			entityTypeInputOptions: [
				"Juridisk person",
				"Fysisk person"
			],
			logo: undefined,
			qp: [],
			company: {},
			union: undefined,
			loggedIn: false,
			error: false,
			failedAuth: undefined,
			redirectURL: undefined,
			loadingLists: false,
			busy: false,
			exceptions: [],
			failed: false,
			auth: {},
			signingMethods: {},
			step: 1,
			steps_emp: [{ title: "", button: "" }],
			steps_client_1: [{ title: "", button: "" }],
			steps_client_2: [{ title: "", button: "" }],
			steps_client_3: [{ title: "", button: "" }],
			forward: undefined,
			countries: [],
			nationalities: [],
			greetings_ins_names: [],
			greetings_ins_and_fin_names: [],
			greetings_insurance: {},
			greetings_insurance_and_finance: {},
			greeting: "",
			owner_options : {},
			dropdown_items: [
				"0001",
				"0002",
				"1481"
			],
			customerType: [],
			edit: false,
			editForm: false,
			ud: {
				board_members: [],
				sign_union: {
					inuion :false,
					nounion:false
				},
				form: "kyc",
				amlSearchSuccessful: false,
				custom: [],
				purpose_text: "",
				company: {
					address: "",
					zip: "",
					town: "",
					registration_date: "",
					org_form: "",
					business_code: "",
					business: "",
					start_date:"",
					employees:"",
					purpose:"",
					businessmodel:""
				},
				contact: {
				},
				finances: {},
				knowledge: {},
				ceo: {
					fname: "",
					lname: "",
					dob: "",
					phone: "",
					other: ""
				},
				chair: {
					fname: "",
					lname: "",
					dob: "",
					phone: "",
					other: ""
				},
				recipient: {
					greeting: ""
				},
				signer: {},
				signer_union: {},
				owners: [],
				entities: [],
				peps: [],
				signers : [],
				signers_list : [],
				owner_question1: {},
				owner_question2: {},
				pep_question: {},
				police_certificate: {
					cert_yes: false,
					cert_no: false
				},
				cert: {
				},
				position_type: {
					permanent: false,
					temporary: false
				},
				trainingLinkClicked: false,
				completedTraining: false
			},
			image: 1,
			trainingImage: 1,
			allImagesChecked: false,
			showImageViewer: false,
			smallWindow: false,
			windowWidth: 0,
			policeCert: undefined,
			files: {},
			certOther: undefined,
			other: "",
			showImageAlert: false,
			showTrainingAlert: false,
			completedTraining: false,
			formOpacity: 0,
			showInvalidFeedback: {
				"datepicker-start-date" : false,
				"datepicker-reg-date" : false,
				"datepicker-ceo" : false,
				"datepicker-chair" : false,
				"datepicker-owner" : false,
				"datepicker-pep": false
			},
			norwayIndex: undefined
		}
	},
	components: {
		Datepicker
	},
	watch: {
		greeting: function (val) {
			for(var i in this.greetings_insurance)
					if(this.greetings_insurance[i].name == val) {
						this.ud.recipient.greeting = this.greetings_insurance[i][this.$root.locale]
						break;
			}
		},
		'ud.recipient.greeting': function (val) {
			this.ud.recipient.greeting = val
		},
		windowWidth: function () {
			if(window.innerWidth <= 800){ 
				this.smallWindow = true;
			}
		},
		lang: function (val) {
			var v = val
			console.log(v)
			this.loadLists()
		}
	},
	methods: {
		validateEntityType(entity) {
			console.log("entity = " + entity.typeInput)
			if (entity.typeInput == "Juridisk person") {
				entity.type = "ORGANIZATION"
			}else {
				entity.type = "PERSON"
			}
			console.log("entity type = " + entity.typeInput)
			this.$forceUpdate()
		},
		onRemoveOwner() {
			this.ud.owners.pop();
			if (this.ud.owners.length < 1) {
				this.ud.owner_question1.owners_A1 = false;
				this.ud.owner_question1.owners_A2 = false;
				this.ud.owner_question1.owners_A2 = false;
			}
		},
		onRemoveEntity() {
			this.ud.entities.pop();
			if (this.ud.entities.length < 1) {
				this.ud.owner_question2.owner_structure_A1 = false;
				this.ud.owner_question2.owner_structure_A2 = false;
			}
		},
		onRemovePep() {
			this.ud.peps.pop();
			if (this.ud.peps.length < 1) {
				this.ud.pep_question.pep_A1 = false;
				this.ud.pep_question.pep_A2 = false;
			}
		},
		selectCompany(d) {
			this.search = false
 			this.ud.company.org_no = String(d.organisasjonsnummer) //orgno.substr(0,3) + " " + orgno.substr(3,3) + " " + orgno.substr(6,3)
			this.ud.company.name = d.navn
			this.ud.company.org_form = d.organisasjonsform.kode
			this.ud.company.org_form_desc = d.organisasjonsform.beskrivelse
			this.ud.company.registration_date = d.registreringsdatoEnhetsregisteret
			this.ud.company.employees = d.antallAnsatte
			this.ud.company.start_date = d.stiftelsesdato

			if (d.forretningsadresse) {
				this.ud.company.address = d.forretningsadresse.adresse.toString()
				this.ud.company.zip = d.forretningsadresse.postnummer
				this.ud.company.town = d.forretningsadresse.poststed
				this.ud.company.country = d.forretningsadresse.land;
			}
			if (d.naeringskode1) {
				this.ud.company.business = d.naeringskode1.beskrivelse
				this.ud.company.business_code = d.naeringskode1.kode
			}
			
			this.ud.company.inBrReg = true
			this.ud.company.inBusinessReg = d.registrertIForetaksregisteret
			this.ud.company.inMvaReg = d.registrertIMvaregisteret
			this.ud.company.bankrupt = d.konkurs
			this.ud.company.liquidation = d.underAvvikling
			this.ud.company.compulsoryLiquidation = d.underTvangsavviklingEllerTvangsopplosning

			this.brregSearchCompleted = true;
		},
		// Return config for given key
		__config(key) {
			if (this.config[key])
				return this.config[key].value
			return null
		},
		__customPage(key) {
			if(this.customPage[key])
				if(this.customPage[key][this.$root.locale])
					return this.customPage[key][this.$root.locale]
				else
					return this.customPage[key].value
		},
		getURL() {
			if (location.search) {
				if (location.search.substring(1, 2) == "&") {
					this.qp = location.search.substring(0, 1) + location.search.substring(2, location.search.length);
					this.qp = JSON.parse("{\"" + decodeURI(qp.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
				} else
					this.qp = JSON.parse("{\"" + decodeURI(location.search.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
			}
		},
		close() {
			location.href = this.redirectURL
		},
		validateForm(form) {
			return this.$refs[form].validate()
		},
		validateDatepicker(id) {
			console.log("validateDatePicker: " + id + "  invalid was= " + this.showInvalidFeedback[id]);
			var element = document.getElementById(id);
			console.log("element: " + element);
			if (element) {
				var value = element.value
				//this.validateForm(`form${this.step-3}`)

				if(!value) {
					this.showInvalidFeedback[id] = true;
					
					console.log("validateDatePicker: " + id + "  invalid now= " + this.showInvalidFeedback[id]);
					return false
				} else {
					this.showInvalidFeedback[id] = false;
					console.log("validateDatePicker: " + id + "  invalid now= " + this.showInvalidFeedback[id]);
					return true
				}
				
			}
		},
		editFormOwner() {
			this.editForm = true
			this.edit = true
			this.loadLists()
			this.$forceUpdate()
			this.step = 1
			
		},
		fadeToStep(step) {
			this.formOpacity = 0
			setTimeout(() => {
				this.step = step
				if(step === 0)
					this.hidePortalContent = false
				setTimeout(() => {
					this.contentOpacity = this.formOpacity = 1
				}, 10)
			}, 500)
		},
		async nextStep() {
			if(!this.editForm ) {
			if(this.role == 'owner') {
				if(this.step == 1)

					if(!this.validateForm('form2')) {
						return
					} else {
						if(this.ud.company.org_no !== undefined) {
							let org = (this.ud.company.org_no.toString()).replace(/\s/g, "")
							if(!isNaN(org)) {
								this.formOpacity = 0
								await this.getAmlData(this.ud.company.org_no)
								setTimeout(function () { this.formOpacity = 1 }.bind(this), 500)
							}
						}
						
					}
				else if (this.step == 2 && !this.edit){
					this.edit = true
					this.loadLists()
					this.$forceUpdate()
					return
				}
				else if(this.step == 2 && this.edit)
					if(!this.validateForm('form8')) {
						return
					}
				if(this.step < 2) {
				this.edit = false
				this.loadLists()
				this.$forceUpdate()
				this.fadeToStep(this.step + 1)
				}
				else 
					return this.submit()

			}
			}
			if(this.editForm){
				if(this.step == 7) {
					if(!this.validateForm('form8'))
						return
				}
				if(this.step < 7)
					this.fadeToStep(this.step + 1)
				else 
					return this.submit()
			}
			if (this.role == 'editor') {
				if (this.step == 1) {
					this.fadeToStep(2)
				}else {

					if (this.step == 2) {
						this.validateDatepicker("datepicker-start-date");
						this.validateDatepicker("datepicker-reg-date");
					}else if (this.step == 3) {
						this.validateDatepicker("datepicker-ceo");
						this.validateDatepicker("datepicker-chair");
					}

					console.log(this.step)
					if (this.step <= 8) {
						if (!this.validateForm(`form${this.step}`)) return
					}

					if (this.step == 2) {
						if (!this.validateDatepicker("datepicker-start-date")) {
							return;
						} 
						else if (!this.validateDatepicker("datepicker-reg-date")) {
							return;
						}
					}else if (this.step == 3) {
						if (!this.validateDatepicker("datepicker-ceo")) {
							return;
						}else if(!this.validateDatepicker("datepicker-chair")) {
							return;
						}
					}

					if(this.step < 8)
						this.fadeToStep(this.step + 1)
					else 
						return this.submit()
				}
				
			}
		},
		previousStep(n) {
			this.step = n
		},
		showOwners() {
			if(this.ud.owner_question1.owners_A1 == true && this.ud.owners.length == 0)
				this.ud.owners.push({})
			else this.ud.owners = []
		},
		showEntities() {
			if(this.ud.owner_question2.owner_structure_A1 == true && this.ud.entities.length == 0)
				this.ud.entities.push({})
			else this.ud.entities = []
		},
		showPeps() {
			if(this.ud.pep_question.pep_A1 == true && this.ud.peps.length == 0)
				this.ud.peps.push({})
			else this.ud.peps = []
		},
		checkSigningMethod() {
			if(this.ud.sign_method.forward){
				this.forward = true
				this.ud.forward = this.forward 
			}
			if(this.ud.sign_method.forward_self){
				this.forward = false
				this.ud.forward = true
			}
			if(this.ud.sign_method.sign){
				this.forward = false
				this.ud.forward = this.forward 
			}	
		},
		checkUnion() {
			console.log(this.ud.sign_union.inuion)
			if(this.ud.sign_union.inuion == true){
				this.union = true
			}
			else this.union = false
		},
		findRole(val) {
			switch(val) {
				case "CEO" : return "Daglig leder";
				case "CHAIRMAN_OF_THE_BOARD" :  return "Styreleder";
				case "BOARD_MEMBER" :  return "Styremedlem";
				default: return val;
			}
		},
		update(company) {
			if(!company) return;
			this.$root.searchOngoing = true
		},
		async searchNr(nr) {
			let link = "https://data.brreg.no/enhetsregisteret/enhet/" + nr + ".json"
			try {
				const [brreg] = await Promise.all([
					axios.get(link)
				])
				return brreg.data
			}catch(e) {
				return
			}
		},
		async doSearch(value) {
			let brregcompany = await this.searchNr(value)
			if (brregcompany) {
				this.brregcompany = brregcompany
				// eslint-disable-next-line no-console
				if (this.debugging) console.log(this.brregcompany);
				this.update(brregcompany)
				return this.brregcompany
			} 
			return null
		},
		async updateEntityOwnersOrg(org, index) {
			// eslint-disable-next-line no-console
			if (this.debugging) console.log(this.brreg_all_data)
			let orgbrreg = await this.doSearch(org)
			if(orgbrreg) {
				// eslint-disable-next-line no-console
				if (this.debugging) console.log(this.ud.entities[index].name);
				this.ud.entities[index].address = this.brregcompany.forretningsadresse.adresse
				this.ud.entities[index].zip = this.brregcompany.forretningsadresse.postnummer
				this.ud.entities[index].town = this.brregcompany.forretningsadresse.poststed
				this.ud.entities[index].country = this.brregcompany.forretningsadresse.land
				this.ud.entities[index].business_code = this.brregcompany.naeringskode1.kode
				// eslint-disable-next-line no-console
				if (this.debugging) console.log(this.brregcompany,this.ud.entities[index].address, this.brregcompany.forretningsadresse.adresse);
			}
		},
		async getAmlData(a) {
			this.edit = false

			const capitalize = (s) => {
				if (typeof s !== 'string') return ''
				var name = ""
				var strings = s.split(" ")

				for(var i in strings) {
					name += strings[i].charAt(0).toUpperCase() + strings[i].toLowerCase().slice(1)
					if(Number(i) !== (strings.length - 1))
						name += " "
				}
				return name
			}

			var dev = false
			// eslint-disable-next-line no-console
			if (this.debugging) console.log("aml init");
			if(this.role == 'owner' && !this.searchComplete && !dev){
				this.searching = true;
				
				// eslint-disable-next-line no-console
				if (this.debugging) console.log(this.searchComplete)
				var company = null

				if(Number(a)) {
					company =  await oidc.norway.getCompanyInfo(a, ["ownership.beneficial","ownership.shareholders","authorizations","authorities","sanction","officialRoles"])
				} else {
					company = a
				}

				this.ud.amlOriginalSearch = company
				// eslint-disable-next-line no-console
				if (this.debugging) console.log("amlOriginalSearch: " + this.amlOriginalSearch)
				
				// Ceo contact person
				if(company.officialRoles && company.officialRoles.ceo) {
					var ceoNames = company.officialRoles.ceo.name.split(" ");  
					this.ud.ceo.fname = capitalize(ceoNames.slice(1).join(" "))
					this.ud.ceo.lname = capitalize(ceoNames[0])
					this.ud.ceo.dob = company.officialRoles.ceo.dateOfBirth
				}
		
				// Chair 
				if(company.officialRoles && company.officialRoles.chairman) {
					var chairNames = company.officialRoles.chairman.name.split(" ");  
					this.ud.chair.fname = chairNames.slice(1).join(" ")
					this.ud.chair.lname = chairNames[0]
					this.ud.chair.dob = company.officialRoles.chairman.dateOfBirth
				}

				// Sanction
				if(company.sanction){
					if(company.sanction.status === 'NONE'){
						this.translations['sanction_message'][this.$root.locale] = company.sanction.message
						this.ud.sanction = false
					}
					if(company.sanction.status != 'NONE'){
						this.translations['sanction_message'][this.$root.locale] = company.sanction.message
						this.ud.pep_question.pep_A1 = true;
						this.peps.push({});
						this.ud.sanction = true
					}
				}

				// Relle rettighetshavere
				if(company.ownership.beneficial && company.ownership.beneficial.length != 0){
					this.ud.owner_question1.owners_A1 = true
					this.ud.ownersFound = true
					for (let i = 0; i < company.ownership.beneficial.length; i++){
						var ownerNames = company.ownership.beneficial[i].name.split(" ");  

						if (company.ownership.beneficial[i].address) {
							this.ud.owners.push({fname: ownerNames.slice(1).join(" "), lname: ownerNames[0], dob: company.ownership.beneficial[i].dateOfBirth, share: company.ownership.beneficial[i].percentageControl, address: company.ownership.beneficial[i].address.street, zip: company.ownership.beneficial[i].address.postalCode, town: company.ownership.beneficial[i].address.city});
						} else {
							this.ud.owners.push({fname: ownerNames.slice(1).join(" "), lname: ownerNames[0], dob: company.ownership.beneficial[i].dateOfBirth, share: company.ownership.beneficial[i].percentageControl, address: '', zip: '', town: ''});
						}

					} 
				} else this.ud.ownersFound = false

				// Styringsmedlemmer
				// eslint-disable-next-line no-console
				/* if (this.debugging) console.log(company.officialRoles.boardMembers) */
				if(company && company.officialRoles && company.officialRoles.boardMembers && company.officialRoles.boardMembers.length != 0){
					// eslint-disable-next-line no-console
					if (this.debugging) console.log(company.officialRoles.boardMembers)
					for (let i = 0; i < company.officialRoles.boardMembers.length; i++){
						var memberNames = company.officialRoles.boardMembers[i].name.split(" ");  
						this.ud.board_members.push({fname: memberNames.slice(1).join(" "), lname: memberNames[0], dob: company.officialRoles.boardMembers[i].dateOfBirth});
						// eslint-disable-next-line no-console
						if (this.debugging) console.log(this.ud.board_members)
					} 
				}else {
					this.ud.board_members.push({})
				} 

				// Eiere Juridisk
				if(company.ownership && company.ownership.shareholders && company.ownership.shareholders.length != 0){
					this.ud.owner_question2.owner_structure_A1 = true
					this.ud.entitesFound = true
					for (let i = 0; i < company.ownership.shareholders.length; i++){
						this.ud.entities.push({name: company.ownership.shareholders[i].name, org_no: company.ownership.shareholders[i].organizationNumber, share: company.ownership.shareholders[i].percentage, type: company.ownership.shareholders[i].type});
						this.updateEntityOwnersOrg(company.ownership.shareholders[i].organizationNumber, i)
					}    
				}else this.ud.entitesFound = false


				// Signatories
				// eslint-disable-next-line no-console
				if (this.debugging) console.log("Signatories")
				if (company.authorities && company.authorities.signatureRights) {
					this.ud.requirementsDescription = company.authorities.signatureRights.description
					// eslint-disable-next-line no-console
					if (this.debugging) console.log(this.ud.requirementsDescription + "aml auth ")
				}
				if (company.authorities && company.authorities.signatureRights && company.authorities.signatureRights.options && company.authorities.signatureRights.options[0] && company.authorities.signatureRights.options[0].required){ 
					for (let i = 0; i < company.authorities.signatureRights.options[0].required.length; i++){
						// eslint-disable-next-line no-console
						if (this.debugging) console.log(this.ud.requirementsDescription + "loooping thr" + company.authorities.signatureRights.options[0].required )
						this.ud.signers.push({name: company.authorities.signatureRights.options[0].required[i].name, role: this.findRole(company.authorities.signatureRights.options[0].required[i].role), dateOfBirth: company.authorities.signatureRights.options[0].required[i].dateOfBirth});
						var role = this.findRole(company.authorities.signatureRights.options[0].required[i].role)

						var signerNames = company.authorities.signatureRights.options[0].required[i].name.split(" ")
						var name = capitalize(signerNames.slice(1).join(" ").split(" (")[0] + " " + signerNames[0])
						var signer = name + " (" + role + ", " + company.authorities.signatureRights.options[0].required[i].dateOfBirth + ")"
						this.ud.signers_list.push(signer)
					}
					this.ud.signers_list.push("Vedkommende er ikke i listen")
					// eslint-disable-next-line no-console
					if (this.debugging) console.log("signers list")
					// eslint-disable-next-line no-console
					if (this.debugging) console.log(this.ud.signers_list)
				}
				this.loadLists()
				this.$forceUpdate()
				this.searching = false
				this.searchComplete = true
				this.ud.amlSearchSuccessful = true
				return

			}
			if(this.role !== 'owner'){
				// eslint-disable-next-line no-console
				if (this.debugging) console.log("redirecting")
				this.e6 = 2;
			}
		},
		async submit() {

			this.formOpacity = 0
			this.busy = true
			this.ud.lang = this.$root.locale
			this.ud.values = this.sheetname
			this.ud.logourl = this.__config('logo') || "https://app.verified.eu/lib/iframes/common/assets/logo-small.png"
			// eslint-disable-next-line no-console
			if (this.debugging) console.log(this.ud.values, this.ud.logourl)

			
			// Fix formatting from datepickers
			var ceoDob = new Date(this.ud.ceo.dob);
			if(!isNaN(ceoDob.getTime())) {
				this.ud.ceo.dob = ceoDob.toISOString().slice(0,10);
			}
			var chairDob = new Date(this.ud.chair.dob);
			if(!isNaN(chairDob.getTime())) {
				this.ud.chair.dob = chairDob.toISOString().slice(0,10);
			}
			if (this.ud.board_members != undefined && this.ud.board_members.length > 0) {
				this.ud.board_members.forEach((member) => {
					var mdob = new Date(member.dob);
					if(!isNaN(mdob.getTime())) {
						member.dob = mdob.toISOString().slice(0,10);
					}
				});
			}
			if (this.ud.owners != undefined && this.ud.owners.length > 0) {
				this.ud.owners.forEach((owner) => {
					var odob = new Date(owner.dob);
					if(!isNaN(odob.getTime())) {
						owner.dob = odob.toISOString().slice(0,10);
					}
				});
			}
			if (this.ud.peps != undefined && this.ud.peps.length > 0) {
				this.ud.peps.forEach((pep) => {
					var mdob = new Date(pep.dob);
					if(!isNaN(mdob.getTime())) {
						pep.dob = mdob.toISOString().slice(0,10);
					}
				});
			}


			if(this.role == 'owner') {
				// eslint-disable-next-line no-console
				if (this.debugging) console.log("submitting")
				this.ud.fileName = "Kundekontroll Juridisk Person - " + this.ud.company.name + " - " + this.ud.company.org_no + ".pdf"
				this.ud.orgNr = this.ud.company.org_no

				if(this.ud.recipient.name && this.ud.recipient.name!= 'Vedkommende er ikke i listen') {
					var names = this.ud.recipient.name.split(" ");  
					this.ud.recipient.lname = names.slice(1).join(" ").split(" (")[0]
					this.ud.recipient.fname = names[0]
				}

				try {

					// Create envelopes etc
					const envelope = await envelopes.create(this.sheetname)

					// Get and set bearer token
					const bearerToken = await envelope.getBearerToken('/flows/simple-public-flow')
					auth.setToken(bearerToken)

					// Parse remote envelope data into the local object
					await envelope.reflect()

					await envelope.put({ greeting: this.ud.recipient.greeting });

					await envelope.firstDocument().put({ name: this.ud.fileName });

					await envelope.firstDocument().setTags([this.greeting]);

					// Set template data
					await envelope.firstTemplate().setUserData(this.ud)

					// Add recipient
					await envelope.addRecipient({
						givenName: this.ud.recipient.fname,
						familyName: this.ud.recipient.lname,
						language: this.$root.locale,
						signingMethod: "bankid-no",
						email: this.ud.recipient.email,
						order: 1,
						role: {
							action: "edit",
							label: "Fylle i skjemaet",
							name: "editor"
						}
					})

					// Publish the envelope
					await envelope.publish()

					// Get the sign token
					/*const signToken = await envelope.getSignToken('/flows/simple-public-flow')*/
					//window.location.href = this.redirectURL

					//Reload page
					var successInfo = this.ud.recipient.fname + " " + this.ud.recipient.lname + " (" + this.ud.recipient.email + ")"
					$cookies.set("success", true);
					$cookies.set("envelope", successInfo);
					location.reload();

				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
				}
			} else {
				this.ud.finalSubmit = true
				this.ud._isForwarded = this.ud.forward === true


				this.unixTime = Math.floor((new Date()).getTime() / 1000)
				this.unixTime += 8640000; // 100 Dager i sekunder

				if(this.ud._isForwarded) this.unixTime += 86400; // Reminder sendes ut etter 24 timer
				else this.unixTime += 1800; // Reminder sendes ut etter 30 min


				// Check signer name
				if (!this.ud.signer.name) {
					this.ud.signer.name = this.ud.signer.fname + " " + this.ud.signer.lname
				}
				if (this.ud.sign_method.sign == true || this.ud.sign_method.forward_self == true) {
					// Same as recipient
					this.ud.signer = this.ud.recipient
				} else if(this.ud.signer.name !== 'Vedkommende er ikke i listen') {
					// Name comes from the list
					var signer = this.ud.signer.name.split(' ')
					this.ud.signer.lname = signer.slice(1).join(" ").split(" (")[0]
					this.ud.signer.fname = signer[0]
				}

				// Check union name
				if (!this.ud.signer_union.name) {
					this.ud.signer_union.name = this.ud.signer_union.fname + " " + this.ud.signer_union.lname
				}
				if (this.ud.signer_union.name !== 'Vedkommende er ikke i listen') {
					// Name comes from the list
					var signer_union = this.ud.signer_union.name.split(' ')
					this.ud.signer_union.lname = signer_union.slice(1).join(" ").split(" (")[0]
					this.ud.signer_union.fname = signer_union[0]
				}

				if(this.ud.amlSearchSuccessful && this.ud.signers.length >= 1) { 
					console.log("Signature match TRUE")
					this.metadata = {"signature_match": true};
				}
				else 
					this.metadata = {"signature_match": false};

				try {
					// Add recipient
					await this.envelope.addRecipient({
						givenName: this.ud.signer.fname,
						familyName: this.ud.signer.lname,
						language: this.$root.locale,
						signingMethod: "bankid-no",
						email: this.ud.signer.email,
						order: 1,
						role: {
							action: "sign",
							label: "Fylle i skjemaet",
							name: "signer"
						}
					})
					if (this.union) {
						// Add recipient
						await this.envelope.addRecipient({
							givenName: this.ud.signer_union.fname,
							familyName: this.ud.signer_union.lname,
							language: this.$root.locale,
							signingMethod: "bankid-no",
							email: this.ud.signer_union.email,
							order: 2,
							role: {
								action: "sign",
								label: "Fylle i skjemaet",
								name: "signer"
							}
						})
					}

					if(this.files.length !== 0)
						for(var f in this.files) 
							await this.envelope.firstDocument().uploadAttachment(this.files[f].name, this.files[f]);
			
					await this.envelope.put({ metadata: this.metadata });
					await this.envelope.put({ greeting: "" });
					await this.envelope.put({ expiration: this.unixTime, automaticReminders: 100 })

					// Set template data
					await this.envelope.firstTemplate().setUserData(this.ud)

					if (this.ud._isForwarded)
						window.location.href = this.redirectURL
					else {
						//Get the sign token
						const signToken = await this.envelope.getSignToken('/flows/simple-public-flow')
						this.signUrl = `https://app.verified.eu/#/sign/envelopes/${this.envelope.data.id}?redirect_to=${this.redirectURL}&access_token=${signToken}`
						localStorage.setItem(this.envelope.data.id, this.signUrl)
						window.location.href = this.signUrl
					}
				}catch(e) {
					// eslint-disable-next-line no-console
					if (this.debugging) console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
				}
			}
		},
		async updateFormData(data) {
			for(var d in data)
				if(d !== 'aml')
					this.ud.company[d] = data[d]
					
			await this.getAmlData(data.aml)
			
			this.loading = false
			this.formOpacity = 1
		},
		loadLists() {
			if(this.ud.custom.length > 0) this.ud.custom = []
			if(this.countries.length > 0) this.countries = []
			if(this.nationalities.length > 0) this.nationalities = []

			for(var a in this.customPage.data){
				console.log(this.customPage)
				if(this.customPage.data[a]['type']=="input")
				this.ud.custom.push({name: this.customPage.data[a][this.$root.locale]})
			}
			for(var c in this.countriesList.data) {
				this.countries.push(this.countriesList.data[c][this.$root.locale])
			}
			this.countries.sort();

			for(var c in this.countries) {
				if(this.countries[c] == "Norge" || this.countries[c] == "Norway")
					this.norwayIndex = Number(c)
			}
			console.log("INDEX NORWAY: " + this.norwayIndex)

			for(var n in this.nationalitiesList.data)
				if(this.nationalities.indexOf(this.nationalitiesList.data[n][this.$root.locale]) === -1)
					this.nationalities.push(this.nationalitiesList.data[n][this.$root.locale]);
			this.nationalities.sort();

			if(this.role === 'owner') {
				if(this.greetings_ins_names.length > 0) this.greetings_ins_names = []
				if(this.greetings_ins_and_fin_names.length > 0) this.greetings_ins_and_fin_names = []

				for(var g1 in this.greetings1List.data) 
					this.greetings_ins_names.push(this.greetings1List.data[g1].name)

				for(var g2 in this.greetings2List.data)
					this.greetings_ins_and_fin_names.push(this.greetings2List.data[g2].name)

				this.greetings_insurance = this.greetings1List.data
				this.greetings_insurance_and_finance = this.greetings2List.data

				this.steps_emp = [
					{ title: this.__("step_1"), button: this.__("step_1_button") },
					{ title:  this.edit ? this.__("step_3") : this.__("step_13"), button: this.edit ? this.__("step_3_button") : this.__("step_13_button")} ,
				]
				this.steps_client_2 = [
					//{ title: this.__("step_5"), button: this.__("step_5_button") },
					{ title: this.__("step_6"), button: this.__("step_6_button") },
					{ title: this.__("step_8"), button: this.__("step_8_button") },
					{ title: this.__("step_7"), button: this.__("step_7_button") },
					{ title: this.__("step_9"), button: this.__("step_9_button") },
					{ title: this.__("step_15"), button: this.__("step_15_button") },
					{ title: this.__("step_11"), button: this.__("step_11_button") },
					{ title: this.__("step_3"), button: this.__("step_3_button") }
				]
			} else {
				this.steps_client_2 = [
					{ title: this.__("step_5"), button: this.__("step_5_button") },
					{ title: this.__("step_6"), button: this.__("step_6_button") },
					{ title: this.__("step_8"), button: this.__("step_8_button") },
					{ title: this.__("step_7"), button: this.__("step_7_button") },
					{ title: this.__("step_9"), button: this.__("step_9_button") },
					{ title: this.__("step_15"), button: this.__("step_1_button") },
					{ title: this.__("step_11"), button: this.__("step_11_button") },
					{ title: this.__("step_4"), button: this.__("step_4_button") }
				]
			}
		}
	},
	async created() {
		Vue.use(VueCookies)
		//Vue.use(Brreg)

		const [countries, nationalities, greetings1, greetings2, custom] = await Promise.all([
			axios.get('https://sheets.web-services.verified.eu/countries/countries'),
			axios.get('https://sheets.web-services.verified.eu/countries/nationalities'),
			axios.get(`https://sheets.web-services.verified.eu/${this.sheetname}/greetings-notify-editor`),
			axios.get(`https://sheets.web-services.verified.eu/${this.sheetname}/greetings-notify-signatory`),
			axios.get(`https://sheets.web-services.verified.eu/${this.sheetname}/custom-page`)
		])

		this.countriesList = countries
		this.nationalitiesList= nationalities
		this.greetings1List = greetings1
		this.greetings2List = greetings2
		this.customPage = custom

		this.zipJson = zipNO

		this.loadLists()
		// eslint-disable-next-line no-console
		if (this.debugging) console.log(this.ud.custom)

		if(window.innerWidth <= 800){ 
			this.smallWindow = true;
		}
		
		if(this.role === 'owner') {
			if(this.$attrs.searchResult) {
				//this.alreadyCompletedSearch = true
				this.brregSearchCompleted = true;
				await this.updateFormData(this.$attrs.searchResult)
			} else {
				this.formOpacity = 1
			}
		} else {
			this.formOpacity = 1
			this.ud = { ...this.ud, ...this.userdata }
			this.$forceUpdate()

		}
		
		this.redirectURL = this.__config('redirect')
		// eslint-disable-next-line no-console
		if (this.debugging) console.log(this.redirectURL)
		this.loading = false
		
		if(window.search !== "") var s = window.location.href + "?success"
		else var s = window.location.href
	}
}